import { Empty, Skeleton, Table } from "antd";
import ButtonGroupFooter from "../buttonGroupFooter";
import "./taskStatus.scss";
import { StatusChip } from "../../utils/components/tags";
import NoDataComponent from "../../utils/components/noDataComp";
import { tableLoading, trackerTableLoading } from "../../utils/utils";

export const TaskStatusComponent = ({
  tasks = [],
  title,
  overallStatus,
  buttonGroup,
  buttonGroupClassName,
  loadingData = false,
  columns = [],
  rowKey = "id",
  className = "",
  onSelectTask = () => {},
  redirect,
}) => {
  return (
    <div className="task-status-wrapper">
      <div className="title-component">
        <span className="title">{title}</span>
        <div className="overall-status-wrapper">
          Overall Status:
          <StatusChip status={overallStatus} />
        </div>
      </div>
      <div className="task-status-div">
        <Table
          dataSource={tasks}
          columns={columns}
          pagination={false}
          className={className + " table table-container"}
          rowHoverable={false}
          loading={trackerTableLoading({
            loading: loadingData,
            text: "Loading...",
          })}
          rowKey={rowKey}
          locale={{
            emptyText: loadingData ? (
              <div className="empty-table"></div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
          }}
        />
      </div>
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={buttonGroupClassName}
      />
    </div>
  );
};

export const TaskStatus = ({ task = {}, onSelectTask = () => {} }) => {
  return (
    <div className="task-div" onClick={() => onSelectTask(task)}>
      <span>{task.taskName}</span>
      <span>
        {" "}
        <StatusChip status={task.status} />
      </span>
    </div>
  );
};

export const TaskStatusScreenSkeleton = () => {
  return (
    <div className="task-status-div">
      {[1, 2, 3].map((item) => (
        <div key={item} className="table-row-skeleton task-div">
          <Skeleton.Input
            style={{ width: "400px", height: "20px" }}
            active
            size="default"
          />
          <Skeleton.Input
            style={{ width: "150px", height: "20px" }}
            active
            size="default"
          />
          <Skeleton.Input
            style={{ width: "150px", height: "20px" }}
            active
            size="default"
          />
          <Skeleton.Input
            style={{ width: "150px", height: "20px" }}
            active
            size="default"
          />
        </div>
      ))}
    </div>
  );
};
