import React from "react";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import CommanDecobarSheetComponent from "../../components/commanDecoderSheetComponent";
import images from "../../components/images/images";
import Aries from "../../locale/en-Us.json";
import { convertToUSDString } from "../../utils/utils";

const AuditorUploadSheetView = ({
  backStep,
  nextStep,
  draftSheetData,
  activeTab,
}) => {
  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
        //disabled: redirect,
      },
      {
        text: Aries.GLOBAL_FINALIZED_BUTTON,
        className: "primary-button",
        fn: nextStep,
        disabled: !draftSheetData,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  const jsonData = {
    table: {
      columns: [
        {
          title: draftSheetData?.[activeTab]?.mappedKey,
          dataIndex: "mappedKey",
          key: "mappedKey",
          editable: true,
          width: 400,
        },
        {
          title: "Current Period",
          dataIndex: "currentPeriod",
          key: "currentPeriod",
          editable: true,
          width: 180,
          render: (text) => convertToUSDString(Number(text)),
        },
        {
          title: "Previous Period",
          dataIndex: "previousPeriod",
          key: "previousPeriod",
          editable: true,
          width: 180,
          render: (text) => convertToUSDString(Number(text)),
        },
      ],

      dataSource:
        draftSheetData?.[activeTab]?.sheet?.rows?.map((row) => {
          if (!row) return null; // Skip invalid rows

          const transformRow = (rowData) => {
            const transformed = {
              id: rowData.id,
              key: rowData.id,
              mappedKey: rowData.mappedKey || "N/A",
              currentPeriod: rowData.values?.[0] || 0, // Default to 0 if undefined
              previousPeriod: rowData.values?.[1] || 0,
            };

            // Only add the `children` property if it has valid data
            if (rowData.children?.length) {
              transformed.children = rowData.children.map(transformRow);
            }

            return transformed;
          };

          return transformRow(row);
        }) || [],
    },
  };

  return (
    <div className="draft-balance-and-income-sheet">
      <h3>{activeTab === "balanceSheet" ? "Balance Sheet" : "Income Statement"}</h3>
      <CommanDecobarSheetComponent
        sheetTableData={jsonData}
        decoderSheetname={"balanceSheet"}
        editAllowed={false}
        //getUpdatedSheetData={getUpdatedBalanceSheetData}
      />
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </div>
  );
};

export default AuditorUploadSheetView;
