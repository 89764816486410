import React, { useState } from "react";
import { Card, Modal, Radio, Select, Space } from "antd";

const DecoderDataUploadModal = ({
  showModel,
  handleClose,
  uploadTrialBalanceSheet,
  uploadDecoderSheet,
  setState,
  companyType,
}) => {
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const handleCancel = () => {
    handleClose();
  };

  const handleProceed = () => {
    if (selectedOption === 1) {
      uploadDecoderSheet(); // Execute uploadDecoderSheet for option 1
    } else if (selectedOption === 2) {
      uploadTrialBalanceSheet(); // Execute uploadTrialBalanceSheet for option 2
    }

    setState((prev) => ({
      ...prev,
      financialYear: selectedYear,
    }));
    handleClose();
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value); // Update selected option
  };

  const handleYearChange = (value) => {
    setSelectedYear(value); // Update selected financial year
  };

  // Calculate current and previous financial years
  const getFinancialYears = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // Months are 0-based
    const startYear = currentMonth > 3 ? currentYear : currentYear - 1;

    const years = [];
    for (let i = 0; i < 4; i++) {
      const fyStart = startYear - i;
      const fyEnd = fyStart + 1;
      const isCurrent = i === 0;
      years.push({
        label: `${fyStart}-${fyEnd} ${isCurrent ? "(Current F.Y.)" : ""}`,
        value: `${fyEnd}-03-31`,
      });
    }
    return years;
  };

  const financialYears = getFinancialYears();

  return (
    <Modal
      open={showModel}
      centered
      width={600}
      destroyOnClose
      style={{ overflow: "auto" }}
      onCancel={handleCancel}
      footer={[]}
      rootClassName="decoder-flow-selector-wrapper"
    >
      <div className="decoder-flow-selector-model">
        <div className="decoder-flow-selector-title">Decoder Flow Selector</div>
        {(companyType?.toLowerCase() === "private" || companyType?.toLowerCase() === "international") && (
          <div className="decoder-flow-selector-financial-year">
            <p className="year-heading">Select Financial Year:</p>
            <Select
              style={{ minWidth: "220px" }}
              options={financialYears}
              onChange={handleYearChange}
              placeholder="Select Financial Year"
            />
          </div>
        )}
        <div className="decoder-flow-selector-details">
          <Radio.Group onChange={handleRadioChange}>
            <Space direction="vertical">
              <Card
                className={`radio-wrapper ${selectedOption === 1 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">Unpublished Financials</p>
                  <p className="flow-description">
                    If you have draft financial statement select this option
                  </p>
                </div>
                <Radio value={1} />
              </Card>

              <Card
                className={`radio-wrapper ${selectedOption === 2 ? "selected" : ""}`}
              >
                <div className="info">
                  <p className="flow-heading">
                    Trial Balance and Chart of Account with Fiscalix Mapping
                  </p>
                  <p className="flow-description">
                    If you have draft trial balance select this option
                  </p>
                </div>
                <Radio value={2} />
              </Card>
            </Space>
          </Radio.Group>
        </div>

        <div className="decoder-flow-selector-footer">
          <button className="button white-button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="primary-button button"
            onClick={handleProceed}
            disabled={selectedOption === null} // Disable if no option is selected
          >
            Proceed to Upload
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DecoderDataUploadModal;
