import { logOut } from "../../components/header/api";

export const defaultAppPermissions = {
  Onboarding: false,
  Budgeting: false,
  Staffing: false,
  PreliminaryAnalytics: false,
  AuditPlanner: false,
  DocumentAnalyzer: false,
  ClosingProcess: false,
  Review: false,
  AuditReport: false,
  AuditClosure: false,
  Policies: false,
  Decoder: false,
  Variance: false,
  Ratios: false,
  Materiality: false,
  MasterAccess: false,
  MicroApp: true,
  AuditPrograms: false,
};

const normalizeKey = (key) => key.replace(/[-\s&]/g, "").toLowerCase();
const handleInvalidRoles = () => {
  console.log("Invalid roles detected. Logging out...");
  localStorage.clear();
  logOut();
};
const updatePermissions = (roles, accessJson, jsonKey) => {
  let permissions = { ...defaultAppPermissions };
  let appAccessJson = accessJson?.[jsonKey];
  if (!Array.isArray(roles)) {
    handleInvalidRoles();
    return null;
  }
  roles?.forEach((role) => {
    const roleAccess = appAccessJson?.[role];
    if (roleAccess) {
      roleAccess.forEach((app) => {
        const normalizedApp = normalizeKey(app);
        for (let key in permissions) {
          if (normalizeKey(key) === normalizedApp) {
            permissions[key] = true;
            break;
          }
        }
      });
    } else {
      console.log("No permissions found for role");
    }
  });

  return permissions;
};

export const getUserPermissions = (roles, accessJson) => {
  return updatePermissions(roles, accessJson, "accessJson");
};

export const getUserEditPermissions = (roles, accessJson) => {
  return updatePermissions(roles, accessJson, "editJson");
};
