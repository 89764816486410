import "../breadcrumbComponent/breadcrumbComponent.scss";
import images from "../images/images";
const BreadCrumb = ({
  mainTitle,
  subTitle,
  title,
  margin = 0,
  titleClassName = "",
  mainPath,
  customStyle,
  customeMainPath,
}) => {
  return (
    <div
      className="breadcrumb-wrapper"
      style={{ padding: margin, ...customStyle }}
    >
      <a className="link" href={mainPath ? "/" + mainPath : "/"}>
        {" "}
        <span className="breadcrumb-main">{mainTitle}</span>
      </a>
      {subTitle && (
        <img
          alt=">"
          className="breadcrumb-right-arrow"
          src={images["right-arrow-2.svg"]}
        />
      )}
      <span className="breadcrumb-sub">{subTitle}</span>
      {title && (
        <div className={titleClassName ? titleClassName : "title"}>{title}</div>
      )}
    </div>
  );
};
export default BreadCrumb;
