import React, { useMemo, useState } from "react";
import ButtonGroupFooter from "../buttonGroupFooter";
import images from "../images/images";
import "./tablecomponent.scss";

import { SearchOutlined } from "@ant-design/icons";
import { Empty, Input, Table } from "antd";
import { EditableCell, EditableRow } from "./editableCell";
import { FilterMenu } from "./filterMenu";
const TableComponent = ({
  label,
  defaultColumns,
  data,
  buttonGroup,
  buttonGroupClassname,
  tableHeader,
  showDivider,
  tableClassName = "",
  toggleFilterDrawer = () => {},
  showFilterMenu = false,
  filterPanels = [],
  handleApplyFilters = () => {},
  handleResetFilters = () => {},
  handleSearchFilters = () => {},
  tableLoading = false,
  scroll,
  rowKey,
  searchOption,
  saveAuditDraftData,
  showLabel = true,
  // documentList,
}) => {
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const [searchTerm, setSearchTerm] = useState("");
  const filteredProjects = useMemo(() => {
    if (!searchTerm.trim()) {
      return data; // Return all projects if search term is empty
    }
    return data?.filter((project) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        project?.name?.toLowerCase().includes(searchTermLower) ||
        project?.name?.includes(searchTermLower) ||
        project?.client?.cikNumber.includes(searchTerm) ||
        project?.clientName?.includes(searchTerm) ||
        project?.clientName?.toLowerCase().includes(searchTerm) ||
        project?.engagementName?.toLowerCase().includes(searchTerm) ||
        project?.engagementName?.includes(searchTerm)
      );
    });
  }, [data, searchTerm]);
  return (
    <div className="table-container">
      {showLabel && (
        <div className="wrapper-label">
          <img
            className="user-icon"
            src={images["aistra-vaa-avatar-1.svg"]}
            loading="lazy"
            alt="User icon"
          />
          <div>
            <div
              className="label"
              dangerouslySetInnerHTML={{ __html: label }}
            ></div>
          </div>
        </div>
      )}
      {!tableLoading.spinning && tableHeader}
      <FilterMenu
        visible={showFilterMenu}
        close={toggleFilterDrawer}
        drawerPanels={filterPanels}
        handleApplyFilters={handleApplyFilters}
        handleResetFilters={handleResetFilters}
        handleSearchFilters={handleSearchFilters}
      />
      {searchOption && (
        <div className="search-wrapper">
          <Input
            placeholder="Search..."
            className="table-search"
            // style={{ height: "40px", width: "300px", boxShadow: "none" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchKeyword state
            prefix={
              <SearchOutlined
                style={{
                  color: "rgba(0,0,0,.25)",
                }}
              />
            }
          />
        </div>
      )}
      <Table
        bordered={showDivider}
        components={components}
        dataSource={filteredProjects}
        columns={defaultColumns}
        pagination={false}
        className={tableClassName}
        loading={tableLoading}
        rowKey={rowKey ? rowKey : (record) => record.docId || record.sourceLink}
        scroll={scroll}
        locale={{
          emptyText:
            tableLoading && tableLoading.spinning ? (
              <div className="empty-table"></div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ),
        }}
      />
      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={buttonGroupClassname}
      />
    </div>
  );
};
export default TableComponent;
