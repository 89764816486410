import API from "./api";

export const getActivityDataApi = async () => {
  try {
    const url = `v1/time-tracker/master-list`;
    const result = await API({ path: url, method: "GET" });
    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getMasterActivityByProjectApi = async (projectName) => {
  try {
    const url = `v1/time-tracker/master-list/activity?projectName=${projectName}`;
    const result = await API({ path: url, method: "GET" });
    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const autoSavDocumentFile = async (projectId, data, dispatch) => {
  try {
    const url = `v1/file/${projectId}/file?finalise=${data.finalise}&docType=${data.docType}`;

    const result = await API({
      method: "POST",
      data: data?.fileData,
      path: url,
      dispatch,
    });

    return result?.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const checkUserLogin = async () => {
  try {
    const url = `/auth/user`;

    const result = await API({
      method: "GET",
      path: url,
      showErrorModal: false,
    });

    return result?.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getEngagementListApi = async (dispatch, stage, substage) => {
  try {
    const url = `/v1/project?stage=${stage}&subStage=${substage}`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const getFileApi = async (id, dispatch) => {
  try {
    const url = `/v1/bucket/${id}`;

    const result = await API({
      method: "GET",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};

export const commanGetApi = async (url, dispatch) => {
  try {
    const result = await API({ method: "GET", path: url, dispatch });
    return result.data;
  } catch (error) {
    console.error("Error in get request:", error);
    return [];
    // Handle error
  }
};
export const commanPostApi = async (url, data = null, dispatch) => {
  try {
    const result = await API({
      method: "POST",
      data: data,
      path: url,
      dispatch,
    });
    return result.data;
  } catch (error) {
    console.error("Error in get request:", error);
    return [];
    // Handle error
  }
};

export const deleteUploadFileApi = async (bucketFileId, dispatch) => {
  try {
    const url = `/v1/file/bucket/${bucketFileId}`;

    const result = await API({
      method: "DELETE",
      path: url,
      dispatch,
    });

    return result.data;
  } catch (error) {
    console.error("Error in GET request:", error);
  }
};
