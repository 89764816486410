import { useState } from "react";
import {
  getSheetNameApi,
  getSheetGuidanceApi,
  getSheetGuidanceSubmitApi,
  getGuidanceRequiredApi,
  getSheetTemplateApi,
  postTrialBalanceSheetUploadApi,
  getTrialBalanceMappingApi,
  getTrialBalanceSheetValidationApi,
  getDraftSheetsApi,
  getDataApi,
  getuploadedSheetFieldApi,
} from "./api";
import { useDispatch } from "react-redux";
import { setloader } from "../../redux/reducers";
import { EMPTY_LOADER_CONFIG } from "../../utils/constants";

export const useGetSheetName = () => {
  const dispatch = useDispatch();
  const [sheetName, setSheetname] = useState([]);
  const getSheetName = async (file_id) => {
    const data = await getSheetNameApi(file_id, dispatch);
    return data;
  };
  return { getSheetName };
};

export const useGetSheetGuidance = () => {
  const dispatch = useDispatch();
  const [sheetGuidance, setSheetGuidance] = useState({});

  const getSheetguidance = async (sheetId, sheets) => {
    setSheetGuidance({});
    const data = await getSheetGuidanceApi(sheetId, sheets, dispatch);
    setSheetGuidance(data);
  };

  return { getSheetguidance, sheetGuidance, setSheetGuidance };
};

export const useGetSheetGuidanceSubmit = () => {
  const dispatch = useDispatch();
  const [submitedSheetReponse, setSubmitedSheetReponse] = useState({});
  const submitSheetguidance = async (sheetId, sheets) => {
    const data = await getSheetGuidanceSubmitApi(sheetId, sheets, dispatch);
    setSubmitedSheetReponse(data);
    return data;
  };

  return { submitedSheetReponse, submitSheetguidance };
};

// export const useGuidanceRequire = () => {
//   const dispatch = useDispatch();
//   const [guidanceRequired, setGuidanceRequired] = useState(false);
//   const getGuidanceRequired = async (project_id) => {
//     const data = await getGuidanceRequiredApi(project_id, dispatch);
//     setGuidanceRequired(data);
//   };
//   return { getGuidanceRequired, guidanceRequired };
// };

export const checkGuidanceRequire = async (
  project_id,
  period,
  statementType
) => {
  const data = await getGuidanceRequiredApi(project_id, period, statementType);
  // if (data === true) {
  const fieldData = await getuploadedSheetFieldApi(project_id, period);
  if (fieldData) {
    const sheetname = await getSheetNameApi(fieldData.id);
    return { fieldData, sheetname, data };
  }
  // }
  // return false;
};

// Trail Balance

export const useGetSheetTemplate = () => {
  const dispatch = useDispatch();
  const [sheetTemplate, setSheetTemplate] = useState({});

  const getSheetTemplate = async (projectId) => {
    setSheetTemplate({});
    const data = await getSheetTemplateApi(projectId, dispatch);
    setSheetTemplate(data);
  };

  return { getSheetTemplate, sheetTemplate, setSheetTemplate };
};

export const useGetSheetValidation = () => {
  const dispatch = useDispatch();
  const [sheetValidationResponse, setSheetValidationResponse] = useState(null);

  const getTrialBalanceSheetValidation = async (projectId) => {
    setSheetValidationResponse(null);
    const data = await getTrialBalanceSheetValidationApi(projectId, dispatch);
    setSheetValidationResponse(data);
  };

  return {
    getTrialBalanceSheetValidation,
    sheetValidationResponse,
    setSheetValidationResponse,
  };
};

export const useGetTrailBalanceMapping = () => {
  const dispatch = useDispatch();
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setLoading] = useState(true);

  const getTrailBalanceMapping = async (projectId) => {
    setLoading(true);
    setMetaData(null);
    const data = await getTrialBalanceMappingApi(projectId, dispatch);
    setMetaData(data);
    setLoading(false);
  };

  return { getTrailBalanceMapping, metaData, setMetaData, metaDataLoading };
};

export const useGetDraftSheet = () => {
  const dispatch = useDispatch();
  const [draftSheetData, setDraftSheetData] = useState(null);
  const [draftSheetLoading, setLoading] = useState(true);

  const getDraftSheetsData = async (projectId, financialYear) => {
    setLoading(true);
    setDraftSheetData(null);
    const data = await getDraftSheetsApi(projectId, financialYear, dispatch);
    setDraftSheetData(data);
    setLoading(false);
  };

  return {
    getDraftSheetsData,
    draftSheetData,
    setDraftSheetData,
    draftSheetLoading,
  };
};

export const useGetDecoderData = () => {
  const dispatch = useDispatch();
  const [decoderDocumentData, setDecoderDocumentData] = useState({});

  const getDecoderData = async (url) => {
    setDecoderDocumentData(null);
    const data = await getDataApi(url, dispatch);
    setDecoderDocumentData(data);
  };

  return { getDecoderData, decoderDocumentData, setDecoderDocumentData };
};

export const useGetProgramsGuidanceV2 = () => {
  const dispatch = useDispatch();
  const [programGuidanceData, setProgramGuidanceData] = useState(null);
  const [loadingProgramGuidance, setLoadingProgramGuidance] = useState(false);
  const getProgramGuidance = async (url) => {
    try {
      setLoadingProgramGuidance(true);
      const result = await getDataApi(url, dispatch);
      if (result) {
        setProgramGuidanceData(result);
        setLoadingProgramGuidance(false);
        // dispatch(setloader(EMPTY_LOADER_CONFIG));
      } else {
        dispatch(setloader(EMPTY_LOADER_CONFIG));
      }
    } catch (error) {
      setLoadingProgramGuidance(false);
      dispatch(setloader(EMPTY_LOADER_CONFIG));
    }
  };
  return {
    getProgramGuidance,
    programGuidanceData,
    setProgramGuidanceData,
  };
};

export const useGetProgramsExtractGuidanceV2 = () => {
  const dispatch = useDispatch();
  const [programExtractGuidanceData, setProgramExtractGuidanceData] =
    useState(null);
  const [loadingProgramExtractGuidance, setLoadingProgramExtractGuidance] =
    useState(false);
  const getProgramExtractGuidance = async (url) => {
    try {
      setLoadingProgramExtractGuidance(true);
      const result = await getDataApi(url, dispatch);
      if (result) {
        setProgramExtractGuidanceData(result);
        setLoadingProgramExtractGuidance(false);
        dispatch(setloader(EMPTY_LOADER_CONFIG));
      } else {
        dispatch(setloader(EMPTY_LOADER_CONFIG));
      }
    } catch (error) {
      setLoadingProgramExtractGuidance(false);
      dispatch(setloader(EMPTY_LOADER_CONFIG));
    }
  };
  return {
    getProgramExtractGuidance,
    programExtractGuidanceData,
    setProgramExtractGuidanceData,
    loadingProgramExtractGuidance,
  };
};
