import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkUserLogin,
  commanGetApi,
  getEngagementListApi,
} from "./api/commonApi";
import { clearLocalSaveData } from "./utils";
import { useDispatch } from "react-redux";

export const useTokenValidation = () => {
  const [isTokenValid, setIsTokenValid] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateToken = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userData"))?.token; // Adjust based on how you store the token
      if (!token) {
        setIsTokenValid(false);
        navigate("/login");
        clearLocalSaveData(dispatch);
        return;
      }

      const isValid = await checkUserLogin();
      setIsTokenValid(isValid?.token);

      if (!isValid) {
        navigate("/login");
        clearLocalSaveData(dispatch);
      }
    } catch (error) {
      console.error("Token validation error:", error);
      setIsTokenValid(false);
      navigate("/login");
      clearLocalSaveData(dispatch);
    }
  };

  return { isTokenValid, validateToken };
};

export const useGetEngagementList = () => {
  const dispatch = useDispatch();
  const [engagementList, setEngagementList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEngagementList = async (stage, substage) => {
    try {
      setLoading(true);
      setEngagementList([]);
      const response = await getEngagementListApi(dispatch, stage, substage);
      setEngagementList(response?.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error in fetching engagement list:", error);
    }
  };

  return { getEngagementList, engagementList, loading };
};

export const useGetUploadDocumentFiles = () => {
  const dispatch = useDispatch();
  const [uploadDocumentFiles, setUploadDocumentFiles] = useState(null);
  const [loadingUploadDocumentFiles, setLoadingUploadDocumentFiles] =
    useState(true);

  const getUploadDocumentFiles = async (url) => {
    try {
      setLoadingUploadDocumentFiles(true);
      setUploadDocumentFiles(null);
      const response = await commanGetApi(url, dispatch);
      setUploadDocumentFiles(response);
      setLoadingUploadDocumentFiles(false);
    } catch (error) {
      setLoadingUploadDocumentFiles(false);
      console.error("Error in fetching files", error);
    }
  };

  return {
    getUploadDocumentFiles,
    uploadDocumentFiles,
    setUploadDocumentFiles,
    loadingUploadDocumentFiles,
  };
};

export const useGetReponseData = () => {
  const dispatch = useDispatch();
  const [documentData, setDocumentData] = useState({});

  const getCommanDocumentData = async (url) => {
    setDocumentData(null);
    const data = await commanGetApi(url, dispatch);
    setDocumentData(data);
  };

  return { getCommanDocumentData, documentData, setDocumentData };
};
