import SheetComponent from "../../components/sheetComponent";
import React, { useState, useRef, useEffect } from "react";
import {
  transformHeadersToColumns,
  transformRowsToDataSource,
} from "./../../utils/utils";
import { Table } from "antd";
import Aries from "../../locale/en-Us.json";
import images from "../../components/images/images";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import { tableLoading } from "../../utils/utils";
import { getFileBlobSyncfusion } from "../../components/sheetComponent/helper";
import { useDispatch } from "react-redux";
import {
  saveProgramDocumentDataApi,
  saveProgramDocumentDataV2Api,
} from "./api";
const DecoderTableGuidance = ({
  data,
  backStep,
  loadingProgramExtractGuidance,
  nextStep,
  programKey,
  finalizeDocument,
}) => {
  const spreadsheetRef = useRef(null);
  const [dataSource, setDataSource] = useState(data?.rows);
  const [highlightCell, setHighlightCell] = useState([]);
  const [excelBase64Str, setExcelBase64Str] = useState(
    data?.fileResponse?.file
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setDataSource(data?.rows);
    setExcelBase64Str(data?.fileResponse?.file);
    if (!data) {
      spreadsheetRef.current = null;
    }
  }, [data]);

  const buttonGroup = {
    left: [
      // {
      //   text: Aries.BUTTON_GLOBAL_SAVE_DRAFT,
      //   className: "secondary-buttons",
      //   // fn: () => saveDraft(excelBase64Str, project_id),
      //   imageLink: images["save-add.svg"],
      //   // disabled: !editAllowed ? !editAllowed : !xlxFile,
      // },
    ],
    right: [
      {
        text: "Back",
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        rightImageLink: images["arrow-right.svg"],
        className: "primary-button",
        fn: nextStep,
      },
    ],
  };
  const handleCellClick = (cellData, rowIndex, columnIndex) => {
    const updatedDataSource = dataSource?.map((row, idx) => {
      if (
        cellData?.cellIdx === row.cells?.[columnIndex]?.cellIdx &&
        cellData?.rowIdx === row.cells?.[columnIndex]?.rowIdx
      ) {
        setHighlightCell([cellData?.rowIdx, cellData?.cellIdx]);
        return {
          ...row,
          cells: row?.cells?.map((cell, cellIdx) => {
            if (cell && cell?.cellIdx === cellData?.cellIdx) {
              return { ...cell, isSelected: true }; // Toggle 'isSelected' for clicked cell
            } else {
              return { ...cell, isSelected: false };
            }
            return cell;
          }),
        };
      }

      return {
        ...row,
        cells: row?.cells?.map((cell) =>
          cell ? { ...cell, isSelected: false } : cell
        ),
      };
    });

    setDataSource(updatedDataSource);
  };
  const handleSaveExcelOnEditCell = async (args, getSheetFromJson) => {
    try {
      if (args.action === "gotoSheet") {
        return;
      }
      const json = await getSheetFromJson();
      const blobData = await getFileBlobSyncfusion(json);
      handleSaveEditedDataToServer(blobData);
    } catch (error) {
      console.log("Error in saving cell data ", error);
    }
  };

  const handleSaveEditedDataToServer = async (blobData) => {
    try {
      const reader = new FileReader();
      reader.onload = async function () {
        const textBase64Str = reader?.result?.toString();
        const excelBase64Str = atob(
          textBase64Str?.replace("data:text/plain;base64,", "")
        )?.replace(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          ""
        );
        setExcelBase64Str(excelBase64Str);
        // setEditFile(true);
        const dataObject = {
          extension: ".xlsx",
          file: excelBase64Str,
          name: data?.fileResponse?.name,
        };
        // if (editFile) {
        // const autosaveExcel = await saveProgramDocumentDataV2Api(
        //   `/v1/program-docs/uploaded/${data?.bucketFileId}/auto-save?key=${encodeURIComponent(programKey)}`,
        //   dataObject,
        //   dispatch
        // );
        // }
      };
      reader.readAsDataURL(blobData);
    } catch (error) {
      console.log("Error in saving cell data ", error);
    }
  };
  return (
    <div className="program-guidance-wrapper">
      <div className="title">Finalization</div>
      <div className="guidance-content-table">
        <SheetComponent
          file={data?.fileResponse}
          spreadsheetRef={spreadsheetRef}
          showExportButton={true}
          className="program-sheet"
          loadingMessage={"Fetching program Document..."}
          handleSaveSheetCb={handleSaveExcelOnEditCell}
          disableMenu={true}
          highlightCellValue={highlightCell}
        />

        <Table
          style={{ overflow: "auto", borderRadius: "6px" }}
          dataSource={transformRowsToDataSource(dataSource)}
          columns={
            !loadingProgramExtractGuidance &&
            transformHeadersToColumns(data?.headers, handleCellClick)
          }
          pagination={false}
          className={"table "}
          rowHoverable={false}
          loading={tableLoading({
            loading: loadingProgramExtractGuidance,
            text: Aries.PROGRAMS_LIST_LOADER,
          })}
          scroll={{ x: "600px" }}
        />
      </div>
      <ButtonGroupFooter
        className="fixed-button-group"
        buttonGroupObject={buttonGroup}
      />
    </div>
  );
};
export default DecoderTableGuidance;
