import { syncfusionSpreedSheetBaseUrl } from "../api/api";

export const exportAsExcel = (spreadsheetRef, fileName) => {
  spreadsheetRef?.current?.saveAsJson().then((Json) => {
    const formData = new FormData();
    formData.append("JSONData", JSON.stringify(Json.jsonObject.Workbook));
    formData.append("saveType", "Xlsx");
    formData.append("fileName", "Worksheet");
    formData.append(
      "pdfLayoutSettings",
      '{"fitSheetOnOnePage":false,"orientation":"Portrait"}'
    );

    fetch(syncfusionSpreedSheetBaseUrl + "save", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
      })
      .then((data) => {
        const reader = new FileReader();
        reader.onload = function () {
          const textBase64Str = reader.result.toString();
          const excelBase64Str = atob(
            textBase64Str.replace("data:text/plain;base64,", "")
          );
          const byteCharacters = atob(
            excelBase64Str.replace(
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
              ""
            )
          );
          const byteArrays = [];
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }
          const byteArray = new Uint8Array(byteArrays);
          const blobData = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const blobUrl = URL.createObjectURL(blobData);
          const anchor = document.createElement("a");
          anchor.download = fileName + ".xlsx";
          anchor.href = blobUrl;
          document.body.appendChild(anchor);
          anchor.click();
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(anchor);
        };
        reader.readAsDataURL(data);
      });
  });
};

export const getFileBlobSyncfusion = async (json, signal = null) => {
  const formData = new FormData();
  formData.append("JSONData", JSON.stringify(json.jsonObject.Workbook));
  formData.append("saveType", "Xlsx");
  formData.append("fileName", "Worksheet");
  formData.append(
    "pdfLayoutSettings",
    '{"fitSheetOnOnePage":false,"orientation":"Portrait"}'
  );

  const response = await fetch(syncfusionSpreedSheetBaseUrl + "save", {
    method: "POST",
    body: formData,
    signal,
  });
  if (response.ok) {
    return response.blob();
  }
};
